import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const PrivateWrapper = ({ children, auth, roles }) => {
  const { isAuth, userInfo } = useSelector((state) => state.auth);
  // 需要登入但未登入,導向登入頁
  if (auth && !isAuth) {
    console.log("auth", auth);
    return <Navigate to="/login" />;
  }
  // 需要特定角色但使用者角色不符,導向首頁
  if (roles && !roles.includes(userInfo.role)) {
    return <Navigate to="/" />;
  }
  return children;
};
export default PrivateWrapper;
