import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  House,
  Chats,
  CalendarDots,
  Storefront,
  UserCircle,
} from "@phosphor-icons/react";

const navItems = [
  { path: "/", label: "首頁", icon: <House size={25} /> },
  { path: "/#", label: "社群", icon: <Chats size={25} /> },
  { path: "/book", label: "預約", icon: <CalendarDots size={25} /> },
  { path: "/#", label: "電商", icon: <Storefront size={25} /> },
  {
    path: "/my",
    label: "我的",
    icon: <UserCircle size={25} />,
    isActive: (location) =>
      location.pathname === "/my" ||
      location.pathname === "/profile-setting" ||
      location.pathname === "/apply-beautician",
  },
];

const BottomNavigation = () => {
  const location = useLocation();

  return (
    <div className="fixed bottom-0 z-30 mx-auto flex w-full max-w-xl justify-around bg-white p-2.5 shadow-up">
      {navItems.map(({ path, label, icon, isActive }) => (
        <Link key={path} to={path}>
          <div
            className={`flex flex-col items-center ${
              (isActive ? isActive(location) : location.pathname === path)
                ? "text-[#F98B8B]"
                : "text-gray-500"
            }`}
          >
            {icon}
            <span className="text-[11px]">{label}</span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default BottomNavigation;
