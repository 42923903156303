import React, { createContext, useContext, useState } from "react";

const RegisterContext = createContext();

export const useRegister = () => useContext(RegisterContext);

export const RegisterProvider = ({ children }) => {
  const [formData, setFormData] = useState({});
  const [verification, setVerification] = useState({});
  const [ssoInfo, setSsoInfo] = useState({});

  const updateFormData = (newData) => {
    setFormData((prevData) => ({ ...prevData, ...newData }));
  };

  const updateVerification = (newData) => {
    setVerification((prevData) => ({ ...prevData, ...newData }));
  }

  const updateSso = (newData) => {
    setSsoInfo((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };
  
  return (
    <RegisterContext.Provider value={{ formData, verification, updateFormData, updateVerification, updateSso, ssoInfo }}>
      {children}
    </RegisterContext.Provider>
  );
};
