import { createSlice } from "@reduxjs/toolkit";
import { request, setToken as _setToken, getToken } from "@/utils";
import { notification } from "antd";

const authStore = createSlice({
  name: "auth",
  initialState: {
    token: getToken() || "",
    userInfo: {
      id: 1,
      name: "顧客",
      phone: "0912345678",
      email: "customer@test.com",
      birthday: "2000-01-01",
      role: "customer",
      avatar: "https://i.pravatar.cc/150?img=1",
    },
    isAuth: true,
  },
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      _setToken(action.payload);
    },
    setUserRole(state, action) {
      state.userInfo.role = action.payload;
    },
  },
});

const { setToken, setUserRole } = authStore.actions;
const authReducer = authStore.reducer;

// async action
const fetchLogin = (loginForm) => {
  return async () => {
    try {
      return await request.post("/auth/login", loginForm);
    } catch (error) {
      console.error("Login error:", error);
      return false;
    }
  };
};
// async action for sign up phone number
const fetchPhoneRegister = (signupForm) => {
  return async () => {
    try {
      const data = await request.post("/auth/phone/register", signupForm);
      return data;
    } catch (error) {
      console.log("error", error);
      if (error.response.data.detail === "Phone number already registered") {
        notification.error({ message: "手機號碼已經註冊過。" });
      }
    }
  };
};
// async action for verification code
const verificationCode = (verification) => {
  return async () => {
    try {
      const data = await request.post("/auth/phone/verify", verification);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  };
};

// async action for verification code
const sso = (verification) => {
  return async () => {
    try {
      const data = await request.post("/user/sso", verification);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  };
};

// async action for set password
const updatePassword = (password) => {
  return async () => {
    try {
      const data = await request.post("/user/password", password);
      return data;
    } catch (error) {
      console.log("error", error);
    }
  };
};

// async action for sign up
const fetchSignup = (signupForm) => {
  return async (dispatch) => {
    try {
      await request.post("/auth/signup/customer", signupForm, {
        withCredentials: true,
      });
      return true;
    } catch (error) {
      console.error("Sign up error:", error);
    }
  };
};

// async action for sign up
const fetchSelfSignup = (signupForm) => {
  return async (dispatch) => {
    try {
      await request.post("/auth/signup/beautician", signupForm, {
        withCredentials: true,
      });
      return true;
    } catch (error) {
      console.error("Sign up error:", error);
    }
  };
};

// async action for sign up
const fetchSalonSignup = (signupForm) => {
  return async (dispatch) => {
    try {
      const res = await request.post("/auth/signup/salon", signupForm, {
        withCredentials: true,
      });
      return res.success;
    } catch (error) {
      console.error("Sign up error:", error);
    }
  };
};

// 更換user role
const changeUserRole = (role) => {
  return async (dispatch) => {
    dispatch(setUserRole(role));
  };
};

export {
  setToken,
  fetchLogin,
  fetchSignup,
  fetchPhoneRegister,
  fetchSelfSignup,
  fetchSalonSignup,
  changeUserRole,
  verificationCode,
  updatePassword,
  sso,
};

export default authReducer;
